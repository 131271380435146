.goForm{
    display: flex;
    align-items: center;
    justify-content: center;
}
.goForm input{
    width: 300px;
}
.goForm button{
    padding: 7px 20px;
}
.quizStartBox{
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.quizStartBox button{
    width: 50%;
}
.quizNumberHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.quizNumberHeader button{
    cursor: pointer;
}
.quizBox{
    display: flex;
    justify-content: space-between;
}
.quizBox button{
    width: fit-content;
}
.quizBox div button+button{
    margin-left: 20px;
}
.btnGroupQuestion button{
    width: 120px;
    margin-top: 20px;
}
.btnGroupQuestion button+button{
    margin-left: 20px;
}

@media(max-width:600px){
    .goForm input{
        width: 250px;
    }
    .goForm button{
        padding: 7px 15px;
    }
    .quizNumberHeader{
        flex-direction: column;
    }
}
.custom-row{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.custom-col{
    width: 48%;
}
.btn-green{
 background-color: #28a745 !important;
}
.btn-green:hover{
background-color: #1d963a !important;
}
.text-sm{
    font-size: .75rem;
}
@media(max-width:991px){
    .custom-row{
        flex-direction: column !important;
    }
    .custom-col{
        width: 100%;
    }
    .custom-col+.custom-col{
        margin-top: 15px;
    }

}

@media(max-width:600px){
   .btnGroup{
        display: flex;
        justify-content: space-between;
    }
}

@media(max-width:480px){
    .goForm input{
        width: 160px;
    }
    .goForm button{
        padding: 7px 10px;
    }
    .quizBox{
        flex-direction: column;
    }
    .quizBox div{
        margin-bottom: 15px;
    }
    .quizBox div {
        display: flex;
        justify-content: space-between;
    }
    .btnGroupQuestion button{
        width: 100px;
    }
}
@media(max-width:320px){
    .btnGroupQuestion{
        display: flex;
        justify-content: space-between;
    }
    .btnGroupQuestion button{
        width: 80px;
    }
}